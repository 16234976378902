<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "PRODUCT COLLECTIONS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "PRODUCT COLLECTIONS",
          active: true
        }
      ],
      checkbox:[],
      colorData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "collectionId",
      sortDesc: false,
      selected:"true",
      fields: [
        { key: "collectionId", sortable: false, label: "SL NO",thStyle: { color: "black", "font-size":"16px"} },
        { key: "collectionName", sortable: true, label: "COLLECTION ",thStyle: { color: "black", "font-size":"16px"} },
        { key: "count", sortable: true, label: "NO OF PRODUCTS" ,thStyle: { color: "black", "font-size":"16px"}},
        { key: "action", label: "ACTION",thStyle: { color: "black", "font-size":"16px"} }
      ],


      collectionData: [],

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.collectionData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.collectionData.length;


    Master.collectionProductList().then((res) => {
      console.log(res)
      this.collectionData = res.data.data;
    })


  },
  methods: {

    viewDetails(data){
      localStorage.setItem("collectionId",data);
      },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <div class="btn-toolbar">
              <router-link to="/collection-products-add"><a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a></router-link>
            </div>


            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="collectionData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >

                  <template v-slot:cell(collectionId)="row">
                    <p class="badge font-size-12">{{row.index+1}}</p>
                  </template>
                  <template v-slot:cell(collectionName)="row">
                    <p class="badge font-size-12">{{row.item.collectionName}}</p>
                  </template>
                  <template v-slot:cell(count)="row">
                    <router-link to="/collection-product-details"><p class="badge font-size-12"  @click="viewDetails(row.item.collectionId)">{{row.item.count}}</p></router-link>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <router-link to="/collection-product-details" :data-date="data.item.collectionId"><a
                        href="javascript:void(0);"
                        class="mr-3 text-warning"
                        @click="viewDetails(data.item.collectionId)"
                        v-b-tooltip.hover
                        title="VIEW"
                    >
                      <i class="mdi mdi-eye font-size-18"></i>
                    </a></router-link>

                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>